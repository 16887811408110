import React, {Component} from "react";
import {Row, Col, Button, Alert} from "react-bootstrap";
import SingleSelectInputFormField from "../formFields/SingleSelectInputFormField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import APIController from "../../controller/APIController";
import LoadingScreen from "../../includes/LoadingScreen";
import DateTimeUtil from "../../util/DateTimeUtil";
import he from "he";


class EvaluationFormInput extends Component {
    constructor(props) {
        super(props);
        this.worksheet = props.worksheet;
        this.onFinishEvaluation = props.onFinishEvaluation;
        this.state = {
            isFetching : true,
            worksheet: props.worksheet,
            evaluation: null
        }

        this.simpleEvaluationMeasureOptions = [
            {name: '---', value: null},
            {name: '1 - systematisch u. vollständig', value: '1.0'},
            {name: '2 - teilweise vollständig', value: '2.0'},
            {name: '3 - unsystematisch u. unvollständig', value: '3.0'},
        ]

        this.workPaceOptions = [
            {name: '---', value: null},
            {name: '1 - zügig', value: '1.0'},
            {name: '2 - reicht aus', value: '2.0'},
            {name: '3 - langsam', value: '3.0'},

        ]

        this.workPostureOptions = [
            {name: '---', value: null},
            {name: '1 - gut', value: '1.0'},
            {name: '2 - zufriedenstellend', value: '2.0'},
            {name: '3 - nicht zufriedenstellend', value: '3.0'},
        ]

        this.practicalSkillsOptions = [
            {name: '---', value: null},
            {name: '5,0 Punkte', value: '5.0'},
            {name: '4,5 Punkte', value: '4.5'},
            {name: '4,0 Punkte', value: '4.0'},
            {name: '3,5 Punkte', value: '3.5'},
            {name: '3,0 Punkte', value: '3.0'},
            {name: '2,5 Punkte', value: '2.5'},
            {name: '2,0 Punkte', value: '2.0'},
            {name: '1,5 Punkte', value: '1.5'},
            {name: '1,0 Punkte', value: '1.0'},
            {name: '0,5 Punkte', value: '0.5'},
            {name: '0,0 Punkte', value: '0.0'},
        ];
    }

    async componentDidMount() {
        await this.reload();
    }


    handleSaveEvaluation = function () {

        var self = this;

        self.setState({
            isFetching: true,
        })

        APIController.updateWorksheetEvaluation(this.state.worksheet.id,
            this.state.evaluation,
            (json) => {
                let updatedWorksheet = json;
                self.setState({
                    isFetching: false,
                })
                self.onFinishEvaluation(updatedWorksheet);
            },
            (err) => {
                window.alert(`Error: ${err}`)
                self.setState({
                    isFetching: false,
                })
            });

    }.bind(this)

    async reload(){
        try {
            this.setState({
                isFetching: true
            });
            let evaluation = await APIController.getEvaluationByWorksheetId(this.state.worksheet.id);

            /*let delaysInSeconds = (1000 * 60 * 4) //5 minutes
            evaluation.createdDate = new Date().getTime()-delaysInSeconds;
            */

            this.setState({
                isFetching: false,
                evaluation: evaluation
            })
        } catch (e) {
            window.alert(e);
            this.setState({
                isFetching: false,
                evaluation: null
            })
        }
    }

    recalculatePraktischeFetigkeitenSumResult(){
        var self = this;
        let atLeastOneValueIsPresent = false;
        let allPoints = 0;
        self.state.evaluation.values.praktische_fertigkeiten.unterfaecher.map((praktische_fertigkeit,index) => {
            if(praktische_fertigkeit.value != null){
                atLeastOneValueIsPresent = true;
                allPoints += parseFloat(praktische_fertigkeit.value);
            }
        });
        if(!atLeastOneValueIsPresent){
            self.state.evaluation.values.praktische_fertigkeiten.points = null;
            self.state.evaluation.values.praktische_fertigkeiten.grade = null;
        }else{
            self.state.evaluation.values.praktische_fertigkeiten.points = parseFloat(allPoints).toFixed(1);
            if(allPoints >= 22.5){
                self.state.evaluation.values.praktische_fertigkeiten.grade = parseFloat("1.0").toFixed(1);
            }else if(allPoints >= 12.5){
                self.state.evaluation.values.praktische_fertigkeiten.grade = parseFloat("2.0").toFixed(1);
            }else{
                self.state.evaluation.values.praktische_fertigkeiten.grade = parseFloat("3.0").toFixed(1);
            }
        }
    }

    getTextFromPoints(points){
        switch (points){
            case "1.0":
                return "1 - überdurchschnittlich";
            case "2.0":
                return "2 - durchschnittlich";
            case "3.0":
                return "3 - unterdurchschnittlich";
        }
        return "---"
    }


    render() {
        if(this.state.isFetching || this.state.evaluation == null){
            return (
                <LoadingScreen />
            );
        }

        this.recalculatePraktischeFetigkeitenSumResult();

        var self = this;
        return(
            <>
                <Row>
                    <Col xs={12} md={5}>
                        <h5>Arbeitsvorbereitung</h5>
                    </Col>
                    <Col xs={12} md={7}>
                        <SingleSelectInputFormField
                            selectedValue={self.state.evaluation.values.arbeitsvorbereitung}
                            options={this.simpleEvaluationMeasureOptions}
                            onChange={(selectionOption) => {
                                self.state.evaluation.values.arbeitsvorbereitung = selectionOption.value;
                                self.setState({evaluation: self.state.evaluation});
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={5}>
                        <h5>Praktische Fertigkeiten</h5>
                    </Col>
                    <Col xs={12} md={7}>
                        {self.state.evaluation.values.praktische_fertigkeiten.points != null &&
                            <>Punkte: {self.state.evaluation.values.praktische_fertigkeiten.points}, Note: {this.getTextFromPoints(self.state.evaluation.values.praktische_fertigkeiten.grade)}</>
                        }
                    </Col>
                </Row>

                {self.state.evaluation.values.praktische_fertigkeiten.unterfaecher.map((praktische_fertigkeit,index) => {
                    return (
                        <>
                            <Row>
                                <Col xs={12} md={5}>
                                    {praktische_fertigkeit.title !== null && praktische_fertigkeit.title !== ""?praktische_fertigkeit.title:<i>Unbenanntes Feld {index+1}</i>}
                                </Col>
                                <Col xs={12} md={7}>
                                    <SingleSelectInputFormField
                                        selectedValue={praktische_fertigkeit.value}
                                        options={this.practicalSkillsOptions}
                                        onChange={(selectionOption) => {
                                            self.state.evaluation.values.praktische_fertigkeiten.unterfaecher[index] =
                                                {title:praktische_fertigkeit.title,value:selectionOption.value};
                                            self.setState(
                                                {
                                                    evaluation: self.state.evaluation
                                                }
                                            )
                                        }}
                                    />
                                </Col>
                            </Row>
                        </>
                        )
                })}
                <Row>
                    <Col xs={12} md={5}>
                        <h5>Arbeitstempo</h5>
                    </Col>
                    <Col xs={12} md={7}>
                        <SingleSelectInputFormField
                            selectedValue={self.state.evaluation.values.arbeitstempo}
                            options={this.workPaceOptions}
                            onChange={(selectionOption) => {
                                self.state.evaluation.values.arbeitstempo = selectionOption.value;
                                self.setState({evaluation: self.state.evaluation});
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={5}>
                        <h5>Arbeitshaltung</h5>
                    </Col>
                    <Col xs={12} md={7}>
                        <SingleSelectInputFormField
                            selectedValue={self.state.evaluation.values.arbeitshaltung}
                            options={this.workPostureOptions}
                            onChange={(selectionOption) => {
                                self.state.evaluation.values.arbeitshaltung = selectionOption.value;
                                self.setState({evaluation: self.state.evaluation});
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={5}>
                        <h5>QS / Dokumentation</h5>
                    </Col>
                    <Col xs={12} md={7}>
                        <SingleSelectInputFormField
                            selectedValue={self.state.evaluation.values.qs_dokumentation}
                            options={this.simpleEvaluationMeasureOptions}
                            onChange={(selectionOption) => {
                                self.state.evaluation.values.qs_dokumentation = selectionOption.value;
                                self.setState({evaluation: self.state.evaluation});
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div align={"center"} style={{marginTop:"1em"}}>
                            <Button style={{marginRight:"1em"}} onClick={this.handleSaveEvaluation}
                                    variant="success"><FontAwesomeIcon icon={faSave} /> Bewertung speichern
                            </Button>
                        </div>
                    </Col>
                </Row>
            </>
        )


    }

}

export default EvaluationFormInput;