import React, {createRef} from 'react';
import {v4 as uuidv4} from "uuid";
import ComponentHeader from "./ComponentHeader";
import TextComponent from "../input/TextComponent";
import Dropzone from "react-dropzone";
import {Badge, Button, Col, Row} from "react-bootstrap";
import TextInputFormField from "../formFields/TextInputFormField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import NumberInputFormField from "../formFields/NumberInputFormField";
import config from "../../config";
import ModelViewer from "../3DViewer";
import _ from "lodash";

class DownloadTextComponent extends TextComponent {
    constructor(props) {
        super(props);
        this.component = props.component;
        this.dropzoneRef = createRef();
        this._handleChange = this._handleChange.bind(this);
        this.updateComponent = props.updateComponent;
        this.onDrop = this.onDrop.bind(this);

    }



    openDialog(){
        // Note that the ref is set async,
        // so it might be null at some point
        if (this.dropzoneRef.current) {
            this.dropzoneRef.current.open()
        }
    };

    _handleChange(id,value){
        this.component.config[id] = value;
        this.updateComponent(this.component);
    }

    static create(){
        return {
            id: uuidv4(),
            type : "download",
            description: "",
            title: "Datei-Download",
            data : {},
            config: {
                files: [],
                imageWidth:100
            }
        }
    }

    onDrop(acceptedFiles){
        var self = this;
        let formData = new FormData();
        acceptedFiles.forEach((file) => {
            if(file.size >= 100 * 1024 * 1024){
                alert("Datei ist zu groß");
                return null;
            }
            formData.append('file',file)
            fetch(
                config.API_HOST_URL+"/files/upload",
                {
                    method: "POST",
                    body: formData
                }
            )
                .then(function(response) {
                    return response.json();
                })
                .then(function(fileResponse) {
                    if(fileResponse.url !== ""){

                        self.component.config['files'].push(
                            fileResponse
                        )
                        self.updateComponent(self.component);
                    }
                    else{
                        alert(fileResponse.message);
                        return null
                    }
                })
                .catch(function(err) {
                    console.log(`Error: ${err}`)
                });
        });
    }

    _updateFileName(component,fileId,newFileDescription){
        component.config.files.map( (file, idx) => {
            if(file.id == fileId){
                component.config.files[idx].description = newFileDescription;
            }
        });
    }

    _removeFileById(component,fileId){
        component.config.files.map( (file, idx) => {
            if(file.id == fileId){
                fetch(
                    `${config.API_HOST_URL}/files/${file.name}`,
                    {
                        method: "DELETE",
                    }
                ).then(function(response) {
                    component.config.files.splice(idx, 1);
                    return response.json();
                })
                component.config.files.splice(idx, 1);
            }
        });
    }

    renderEditorView(){
        return (
            <div>
                <Dropzone key={"Dropzone" + Math.random().toString()} onDrop={this.onDrop} ref={this.dropzoneRef} noClick noKeyboard>
                    {({getRootProps, getInputProps, acceptedFiles}) => {
                        return (
                            <div>
                                <div {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    <p>Drag & Drop Dateien hier</p>
                                    <button
                                        type="button"
                                        onClick={() => this.openDialog()}
                                    >
                                        Datei auswählen
                                    </button>
                                </div>
                                <br />
                                {this.component.config['files'].map(file => (
                                    <Row key={file.id}>
                                        <Col  className={'mb-1'} xs={12} lg={5}>
                                            <TextInputFormField
                                                title={""}
                                                rows={1}
                                                placeholder={"Titel/Beschreibung"}
                                                description={""}
                                                onChange={(value) => {
                                                    this._updateFileName(this.component,file.id,value);
                                                    this.updateComponent(this.component);
                                                }}
                                                value={file.description}
                                            />
                                        </Col>
                                        <Col className={'mb-1'} xs={12} lg={2}>
                                            <Button style={{marginRight:'0.5em'}} onClick={() => {
                                                window.location.href = file.url;
                                            }}>
                                                <FontAwesomeIcon icon={faDownload} />
                                            </Button>
                                            <Button onClick={() => {
                                                this._removeFileById(this.component,file.id);
                                                this.updateComponent(this.component);
                                            }}>
                                                <FontAwesomeIcon icon={faTrashCan} />
                                            </Button>
                                        </Col>
                                        <Col className={'mb-1'} xs={12} lg={5}>
                                            {(file.type == "image/png"
                                                || file.type== "image/jpeg"
                                                || file.type== "image/jpg")
                                                ? <img src={file.url} style={{height:'2em'}} />
                                                : (file.type == "application/octet-stream")
                                                    ? <ModelViewer file={file} /> : <Badge bg={"dark"}>{file.extension}</Badge>
                                            }
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                        );
                    }}
                </Dropzone>
            </div>
        );
    }

    renderInputView(){
        var imageWidthInProcent = "calc("+this.component.config.imageWidth+'% - 1em)';
        const shouldBeDisabled = _.has(this.component,'writeModeBasedOnPhase')? !this.component.writeModeBasedOnPhase: false;
        return (
            <div className={this.component.type}>
                {this.component.config['files'].map(file => (
                    <div>
                        <Row key={file.id}>
                            <Col xs={12} lg={3}>
                                <Button style={{width:'100%',marginRight:'0.5em'}} disabled={shouldBeDisabled} onClick={() => {
                                    window.open(file.url, '_blank');
                                }}>
                                    <FontAwesomeIcon icon={faDownload} /> Download
                                </Button>
                            </Col>
                            <Col xs={3} lg={6}>
                                <h5>{file.description}</h5>
                            </Col>
                            <Col xs={3} lg={2}>
                                <Badge bg={"dark"}>{file.extension}</Badge>
                            </Col>
                        </Row>
                        <br />
                    </div>
                ))}
            </div>
        );
    }

    renderInstructorView(){
        var imageWidthInProcent = "calc("+this.component.config.imageWidth+'% - 1em)';
        return (
            <div className={this.component.type}>
                {this.component.config['files'].map(file => (
                    <div>
                        <Row key={file.id}>
                            <Col xs={12} lg={3}>
                                <Button style={{width:'100%',marginRight:'0.5em'}} onClick={() => {
                                    window.open(file.url, '_blank');
                                }}>
                                    <FontAwesomeIcon icon={faDownload} /> Download
                                </Button>
                            </Col>
                            <Col xs={3} lg={6}>
                                <h5>{file.description}</h5>
                            </Col>
                            <Col xs={3} lg={2}>
                                <Badge bg={"dark"}>{file.extension}</Badge>
                            </Col>
                        </Row>
                        <br />
                    </div>
                ))}
            </div>
        );
    }

    render() {
        if(this.props.instructorMode){
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderInstructorView()}
            </div>
        } else if(this.props.showEditorView){
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderEditorView()}
            </div>
        }else{
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderInputView(this.instructorMode)}
            </div>
        }
    }
}
export default DownloadTextComponent;